<template>
    <div class="view">
        <el-select v-model="postData.object"
                   filterable
                   :placeholder="lang['html.el-select.placeholder']"
                   style="margin-right: 5px;width: 100%;margin-bottom: 10px"
        >
            <el-option
                v-for="item in agentList"
                :key="item.id"
                :label="item.agentUserName"
                :value="item.agentUserName"
            />
        </el-select>
        <el-input
            v-model="postData.keyList"
            :rows="10"
            type="textarea"
            :placeholder="lang['html.el-input.placeholder']"
            :disabled="isDisabled"
            v-loading="isLoading"
        />

<!--        [提交类型] 1=卡密 2=条形码-->
        <el-radio-group v-model="postData.keyType">
            <el-radio :label="1">{{lang['html.el-radio-group.label.1']}}</el-radio>
            <el-radio :label="2">{{lang['html.el-radio-group.label.2']}}</el-radio>
        </el-radio-group>

        <el-tag type="info" style="margin: 10px">{{lang['html.el-tag.tips']}}</el-tag>
        <br>

        <el-popconfirm :title="lang['html.el-popconfirm.title']" @confirm="submit">
            <template #reference>
                <el-button type="primary" :disabled="isDisabled">{{lang['html.el-button.submit']}}</el-button>
            </template>
        </el-popconfirm>

        <el-button type="warning" @click="postData.keyList = ''" :disabled="isDisabled">{{lang['html.el-button.reset']}}</el-button>
        <el-tag style="margin-left: 10px">Keys: {{getKeyNum}}</el-tag>
    </div>
</template>

<script>
import {fromBase64} from "js-base64";

export default {
    name: "keyMove",
    data(){
        return {
            isDisabled:false,
            isLoading:true,
            postData:{
                keyList:'',//迁移的卡密列表
                object:'',//卡密新代理商账号
                keyType:1,//[提交类型] 1=卡密 2=条形码
            },
            agentList:[],//下级代理商列表
            lang:this.$lang.getLang('key','keyMove'),
        }
    },
    computed:{
        getKeyNum(){
            let res = this.getKeyListArr();
            if(res){
                return res.length;
            }
            return 0;
        }
    },
    methods:{
        //取过滤后的待操作卡密数组
        getKeyListArr(){
            if (this.postData.keyList == ''){
                return null;
            }
            let arr = this.postData.keyList.split('\n');
            let res = [];
            for (let arrKey in arr) {
                let str = arr[arrKey];
                if (str.length > 8){
                    res.push(str);
                }
            }
            return res.length === 0 ? null : res;
        },
        //提交请求
        submit(){
            if (this.postData.object.length <= 0){
                this.$alert(this.lang['submit.error.newAgent']);
                return;
            }
            let arr = this.getKeyListArr();
            if (!arr){
                this.$alert(this.lang['submit.error.keyList']);
                return;
            }
            this.$message(this.lang['submit.wait']);
            this.isLoading = this.isDisabled = true;
            this.$api.post('Key.keyMove/batchMoveKey',{
                object:this.postData.object,
                keyList:arr.toString(),
                keyType:this.postData.keyType,
            }).then(res=>{
                this.isLoading = this.isDisabled = false;
                let data = this.$helper.checkRes(res);
                if(!data){
                    //调用Api失败,提示错误消息
                    let code = this.$helper.getResErrorCode(res);
                    this.$helper.alertErrMsg(this.lang,'submit.res.code.',code,);
                    return;
                }
                this.$notify(this.lang['submit.jump.wait']);
                let item = data.data;
                if (item == null){
                    this.$alert(this.lang['submit.ready.error']);
                    return;
                }
                this.$helper.jumpResultPage(item,this.lang,'submit.res.data.state.',this.lang['submit.jump.title'])
            }).catch(err=>{
                this.isLoading = this.isDisabled = false;
                this.$helper.axiosCatch(err);
            });
        },
        /**
         * 获取下级代理商列表
         */
        getAgentList(){
            this.$message(this.lang['getAgentList.wait']);
            this.$api.post('Agent.AgentData/getSubordinateAgent').then(res=>{
                let data = this.$helper.checkRes(res);
                if (!data){
                    this.$alert(this.lang['getAgentList.error.agentList']);
                    return;
                }
                let count = res.data.count ?? 0;
                if (Number(count) <= 0){
                    this.$alert(this.lang['getAgentList.error.null']);
                }
                this.agentList = res.data.data;
            })
        }
    },
    mounted() {
        let keyList = this.$route.params.keyList;
        if (keyList){
            this.$notify('Importing data..');
            let str = fromBase64(keyList);
            this.postData.keyList = str.replace(/,/g,"\n");
        }
        this.getAgentList();
        this.isLoading = false;
    }
}
</script>

<style scoped>
.view{
    width: 450px;
    margin:0 auto;
    margin-top: 20px;
}
</style>